import { storeById } from '../api/index';
import { getColor } from '../util/anyFunctions';

const useStoreById = async(storeId, categoryValue) =>{
    const response = await storeById({storeId})

    if (response && response.data && response.data.store) {
        response.data.store.color = getColor(categoryValue);
    }

    return {store: response?.data?.store|| {}}
}

export default useStoreById;